<template>
  <div>
    <div class="dk-card">
      <div class="dk-card-title">현재 표출중인 스케줄</div>

      <div v-if="ledDownloadedSchedule" class="led-schedule-body">
        <div v-for="(item, seq) in ledDownloadedSchedule.ledMessages" :key="seq" class="led-message">
          <div class="led-message-image">
            <led-preview-viewer :ledmessage="item" :scale="2"></led-preview-viewer>

            <!-- <img :src="item.thumbImageFile" /> -->
          </div>
          <div class="led-message-desc">
            <div>{{ item.ledMessageName }}</div>
            <div>{{ item.displayTime }}초</div>
          </div>
        </div>
      </div>
      <div style="margin: 10px 0;">
        <el-button @click="showDialog"> <i class="fas fa-play"></i>&nbsp;스케줄 설정 </el-button>
        <el-button @click="weather">기상정보 표출</el-button>
        <el-button @click="emergencyBell">비상벨 알람</el-button>
      </div>
    </div>
    <dialog-led-schedule ref="dialogLedSchedule" @completeLedSchedule="completeLedSchedule"></dialog-led-schedule>
  </div>
</template>
<script>
import _ from 'lodash';
import dialogLedSchedule from './dialogLedSchedule';
import ledPreviewViewer from '../led/form/ledPreviewViewer.vue';

export default {
  components: {
    'dialog-led-schedule': dialogLedSchedule,
    'led-preview-viewer': ledPreviewViewer,
  },
  name: 'dk-led-matrix',
  label: 'DK디지털 사이니즈',
  created() {
    this.getDownloadedLedSchedule();
  },
  mounted() {},
  data() {
    return {
      ledDownloadedSchedule: null,
    };
  },
  props: {
    fcltData: {
      required: true,
    },
  },
  methods: {
    async getDownloadedLedSchedule() {
      let param = {};
      param.fcltId = this.fcltData.fcltId;
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledDownloadedSchedule`, {
          params: param,
        });
        this.ledDownloadedSchedule = response.data.docs || null;
      } catch (err) {}
    },
    completeLedSchedule(result) {
      this.getDownloadedLedSchedule();
    },
    showDialog() {
      this.$refs.dialogLedSchedule.showDialog(this.fcltData);
    },
    async emergencyBell() {
      let param = { ...this.fcltData };
      this.loading = this.$loading({
        lock: true,
        text: 'LED 비상벨 메시지를 발생 합니다',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      param.fcltId = this.fcltData.fcltId;
      try {
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledEmergencySchedule/proc`, { data: param });
        this.loading.close();
      } catch (err) {}
    },
    async weather() {
      let param = { ...this.fcltData };
      this.loading = this.$loading({
        lock: true,
        text: 'LED 기상전광판 메시지를 표출합니다',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      param.fcltId = this.fcltData.fcltId;
      try {
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledWeather/proc`, { data: param });
        this.loading.close();
      } catch (err) {}
    },
  },
};
</script>

<style scoped>
.led-schedule-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.led-schedule-body .led-message {
  border-bottom: 1px solid rgb(233, 233, 233);
  height: 80px;
  position: relative;
}
.led-schedule-body .led-message .led-message-image {
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding-top: 15px;
  position: absolute;
  right: 100px;
  top: 0;
}
.led-schedule-body .led-message .led-message-image img {
  height: 80%;
  width: auto;
}
.led-schedule-body .led-message .led-message-desc {
  bottom: 0;
  padding-top: 15px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  width: 95px;
}
</style>
