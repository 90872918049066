var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "dk-card" }, [
        _c("div", { staticClass: "dk-card-title" }, [
          _vm._v("현재 표출중인 스케줄")
        ]),
        _vm.ledDownloadedSchedule
          ? _c(
              "div",
              { staticClass: "led-schedule-body" },
              _vm._l(_vm.ledDownloadedSchedule.ledMessages, function(
                item,
                seq
              ) {
                return _c("div", { key: seq, staticClass: "led-message" }, [
                  _c(
                    "div",
                    { staticClass: "led-message-image" },
                    [
                      _c("led-preview-viewer", {
                        attrs: { ledmessage: item, scale: 2 }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "led-message-desc" }, [
                    _c("div", [_vm._v(_vm._s(item.ledMessageName))]),
                    _c("div", [_vm._v(_vm._s(item.displayTime) + "초")])
                  ])
                ])
              }),
              0
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-button", { on: { click: _vm.showDialog } }, [
              _c("i", { staticClass: "fas fa-play" }),
              _vm._v(" 스케줄 설정 ")
            ]),
            _c("el-button", { on: { click: _vm.weather } }, [
              _vm._v("기상정보 표출")
            ]),
            _c("el-button", { on: { click: _vm.emergencyBell } }, [
              _vm._v("비상벨 알람")
            ])
          ],
          1
        )
      ]),
      _c("dialog-led-schedule", {
        ref: "dialogLedSchedule",
        on: { completeLedSchedule: _vm.completeLedSchedule }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }